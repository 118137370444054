import { render, staticRenderFns } from "./ModalHintMath.vue?vue&type=template&id=24365ab9&scoped=true&lang=pug&"
import script from "./ModalHintMath.vue?vue&type=script&lang=ts&"
export * from "./ModalHintMath.vue?vue&type=script&lang=ts&"
import style0 from "./ModalHintMath.vue?vue&type=style&index=0&id=24365ab9&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24365ab9",
  null
  
)

export default component.exports